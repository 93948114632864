.fieldsales-overlay .toggle-buttons > div:hover {
  cursor: pointer;
}

.waiting-for-confirmation,
.sms-verified,
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border: 2px solid #1d2962;
  border-radius: 7px;
  font-size: 24px;
  margin-bottom: 1rem;
}

.box > *:first-child {
  flex: 1;
  font-size: 24px;
}

.box > *:last-child {
  margin-left: 2rem;
}

.input_wrapper.disabled {
  opacity: 0.2;
}

.input_wrapper.more-spacing {
  margin-top: 35px;
  margin-bottom: 35px;
}

.input_wrapper.less-bottom-spacing {
  margin-bottom: 15px;
}

.heading-container {
  display: flex;
  flex-direction: column;
}

.sign-confirm-container .box {
  display: flex;
  flex-direction: column;
}

// FOO START
@media (min-width: 600px) {
  .heading-container {
    flex-direction: row;
  }
  .sign-confirm-container .box {
    flex-direction: row;
  }
}
// FOO END

.heading-container h1 {
  flex: 1;
}

.heading-container span {
  white-space: nowrap;
}

h1.space-above {
  margin-top: 35px;
}

.buttons {
  margin-top: 2rem;
}

button.link {
  background-color: transparent !important;
}

.debug_notice {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 800;
  border-radius: 5px;
}

.showing-debug-info .debug_container {
  height: 200px;
  overflow: auto;
}

.showing-minimized-debug-info .debug_container {
  position: fixed;
  top: 10px;
  right: 10px;
}

.debug_element {
  background-color: orange;
  padding: 1rem;
}

/*
.items {
  padding: 2rem;
  margin-bottom: 2rem;
  border: 2px solid #1d2962;
  border-radius: 7px;
}
*/

.items {
  margin-bottom: 2rem;
}

.items-list > .item {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid #1d2962;
  border-radius: 7px;
  background-color: #eef8e7;
  // background-color: #f1f3f5;
}

.items-list > .item .main-wrapper {
  display: flex;
}

.lvl-tm .items-list > .item .secondary-wrapper .input_wrapper:last-of-type {
  margin-top: 1rem;
  margin-bottom: 0;
}

.items.power .items-list > div {
  background-color: #e8f0f8;
}

.items.power .buttons button {
  background-color: #7a96d1;
}

.items-list > div .main {
  flex: 1;
  display: flex;
}

.items-list > div .main .sub {
  font-size: 30px;
  font-weight: 600;
  margin-right: 4rem;
}

.items-list > div .main .person {
  margin-top: 0.4rem;
}

.items-list > div .main .name {
  font-size: 22px;
  font-weight: 600;
}

.items-list > div .owner-label {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 1rem;
}

.items-list > div .campaigns {
  // position: absolute;
  // right: 0;
  // bottom: 0;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 0.4rem;
  padding-right: 2rem;
}

.lvl-tm .fieldsales-overlay > div {
  overflow: scroll;
  top: 5%;
  left: 5%;
  bottom: 5%;
  right: 5%;
}

.lvl-tm .showing-debug-info .fieldsales-overlay {
  top: 200px;
}

.lvl-tm .fieldsales-overlay p {
  margin-bottom: 1rem;
}

.lvl-tm button.icon {
  // background-color: # !important;
  // border: none !important;
}

.lvl-tm button.icon:before {
  padding-right: 0;
  margin-left: 0;
}

.heading-and-button {
  display: flex;
  margin-bottom: 2rem;
}

.heading-and-button h1 {
  flex: 1;
}

.lvl-tm table.receipt tr.heading td {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  font-size: 24px;
  font-weight: bold;
}

.lvl-tm table.receipt td {
  font-size: 18px;
  vertical-align: top;
  padding-right: 1rem;
}

.lvl-tm table.receipt td div {
  font-size: 18px;
}

.lvl-tm table.receipt td:first-child {
  font-weight: bold;
}

.lvl-tm .discreet {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.lvl-tm table.debug td {
  color: #ccc;
  font-size: 12px;
  vertical-align: top;
  padding-right: 0.5rem;
}

.lvl-tm table.debug td:first-child {
  font-weight: bold;
}

.lvl-tm .geo-banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lvl-tm .geo-banner.step__main__inner {
  width: 80%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.02);
  border: 2px solid #1d2962;
  border-radius: 7px;
}

.lvl-tm .geo-banner.step__main__inner .geo-expl {
  margin-bottom: 2rem;
}

.lvl-tm .geo-banner.step__main__inner .geo-error-container {
  padding: 2rem;
  margin: 2rem 0;
  background-color: orange;
  color: white;
  border: 2px solid #1d2962;
  border-radius: 7px;
}

.lvl-tm .geo-banner.step__main__inner .geo-error-container button {
  background-color: red;
}

.lvl-tm
  .geo-banner.step__main__inner
  .geo-error-container
  .geo-error-outro
  + .geo-error-outro {
  margin-top: 1rem;
}

.lvl-tm .geo-banner.step__main__inner .geo-error {
  margin: 2rem 1rem;
  padding: 0 1.5rem;
  color: #444;
  border-left: 5px solid red;
}

span.link {
  font-weight: bold;
  text-decoration: underline;
}

span.link:hover {
  cursor: pointer;
}

button.small {
  color: rgba(255, 255, 255, 0.5) !important;
  padding: 2px 5px !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.lvl-tm .approve-terms h2 {
  margin-top: 4rem;
  font-size: 24px;
}

.lvl-tm .memberContainer {
  background-color: #f0f7e8;
  border: 2px solid #a1d078;
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 7px;
}
.lvl-tm .memberContainer.unset {
  background-color: white;
  border: 2px dashed #999;
}
.lvl-tm .memberContainer.owner {
  border: 3px solid #a1d078;
}
.lvl-tm .memberContainer.editing {
  border: 2px solid #999 !important;
}
.lvl-tm .memberContainer .main {
  display: flex;
}
.lvl-tm .memberContainer .main > .icon {
  width: 80px;
  height: 70px;
}
.lvl-tm .memberContainer .main > .icon.editing {
  background-color: #f0f7e8;
  border: 2px solid #a1d078;
}
.lvl-tm .memberContainer .main > div {
  flex: 1;
}
.lvl-tm .memberContainer .main .name {
  font-size: 24px;
  font-weight: bold;
}
.lvl-tm .memberContainer .main .ownerWantsSubscription {
  font-weight: bold;
}
.lvl-tm .memberContainer .editingContainer {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eaeaea;
}

.lvl-tm .dataPriceBoxes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.lvl-tm .dataPriceBoxes .dataPriceBox {
  flex-basis: calc(33% - 16px);
  border: 1px solid #999;
  border-radius: 5px;
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
  margin: 8px;
}
.lvl-tm .dataPriceBoxes .dataPriceBox.selected {
  padding: 14px 20px;
  border: 2px solid #537e38;
  background-color: #f0f7e8;
}
.lvl-tm .dataPriceBoxes .dataPriceBox .dataAmount {
  font-size: 20px;
  font-weight: bold;
  color: #537e38;
}
.lvl-tm .dataPriceBoxes .dataPriceBox .dataPrice {
  font-size: 24px;
  font-weight: bold;
}

.lvl-tm .familyMemberCounts {
  display: flex;
  margin-bottom: 20px;
}
.lvl-tm .familyMemberCounts > div.counts {
  justify-content: center;
  align-content: center;
  flex: 1;
  text-align: center;
  padding-bottom: 30px;
}
.lvl-tm .familyMemberCounts > div.counts:first-child {
  border-right: 3px solid #eaeaea;
}
.lvl-tm .familyMemberCounts > div.counts h2 {
  font-size: 22px;
  margin-bottom: 0px;
}
.lvl-tm .familyMemberCounts > div.counts p.ageDescription {
  font-size: 16px;
  margin-top: 0px;
}
.lvl-tm .familyMemberCounts > div.counts div.countSelectors {
  display: flex;
  justify-content: center;
}
.lvl-tm .familyMemberCounts > div.counts div.countSelectors .minus,
.familyMemberCounts > div.counts div.countSelectors .plus {
  background-color: #537e38;
  color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 0px 20px;
  cursor: pointer;
  user-select: none;
}
.lvl-tm .familyMemberCounts > div.counts div.countSelectors .minus.disabled,
.familyMemberCounts > div.counts div.countSelectors .plus.disabled {
  background-color: #ccc;
}
.lvl-tm .familyMemberCounts > div.counts div.countSelectors .count {
  width: 30px;
}
.lvl-tm .familyMemberCounts > div.counts div.countSelectors > div {
  font-size: 36px;
}

.lvl-tm .lightbox_wrapper {
  left: 0;
  z-index: 100000;
}

.lvl-tm .switch-lookup-method-container {
  text-align: right;
}

.lvl-tm .owner-registration-container .person-lookup-container {
  margin-bottom: 4rem;
}

.lvl-tm .owner-verified-container {
  margin-top: 20px;
  border: 2px solid #d6e9c6;
  background-color: #dff0d8;
  border-radius: 7px;
  padding: 10px;
  color: #3c763d;
  font-size: 18px;
  text-align: center;
}

.lvl-tm .owner-verified-container .fa-icon {
  margin-right: 5px;
  color: #3c763d;
}

.fa-icon {
  font-family: "Font Awesome 5 Pro" !important;
  margin-right: 5px;
  color: #3c763d;
}

.lvl-tm .add-button-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #c1f098;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px #aaa;
}

.lvl-tm .add-button-container:hover {
  cursor: pointer;
  background-color: #b1e088;
}

.lvl-tm .add-button-container:hover button {
  background-color: #6da83a;
}

.lvl-tm .add-button-container > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lvl-tm .add-button-container > div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.lvl-tm .add-button-container i {
  font-size: 80px;
}

.lvl-tm .add-button-container button {
  width: 100%;
}

.lvl-tm .or-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #aaa;
}

.lvl-tm .special-campaign-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  border-radius: 5px;
  background-color: #333;
  text-align: center;
  color: #fff;
}

.lvl-tm .special-campaign-container * {
  color: inherit;
}

.lvl-tm .special-campaign-container .heading {
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
}

.lvl-tm .special-campaign-container .sub-heading {
  font-size: 16px;
  font-weight: 400;
}

.lvl-tm .special-campaign-container .offer {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 26px;
  font-weight: 800;
  color: #a1d078;
}

.lvl-tm .special-campaign-container .fine-print {
  width: 60%;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 200;
  color: #aaa;
}

.lvl-tm .no-more-info-needed {
  font-size: 24px;
}

.lvl-tm .no-more-info-needed p {
  font-size: 24px;
}
.lvl-tm .sub-summary {
  margin-bottom: 2rem;
}

.lvl-tm .sub-summary-box {
  padding: 2rem;
  background-color: #f3f4f5;
  border-radius: 5px;
}

.lvl-tm .monthly-price-text {
  font-size: 22px;
}

.lvl-tm .monthly-price-text {
  font-size: 22px;
}

.lvl-tm .monthly-price-text > div {
  font-size: 22px;
}

.lvl-tm .monthly-price-text > div:last-child {
  margin-top: 1rem;
}

.lvl-tm .monthly-price-text strong {
  font-size: inherit;
}

.lvl-tm .shared-invoice-options {
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 5px;
  background-color: #eee;
}

.lvl-tm .shared-invoice-options .intro {
  font-size: 22px;
  margin-bottom: 0.7rem;
}

.lvl-tm .shared-invoice-options .input_wrapper {
  margin-bottom: 0 !important;
}

.lvl-tm .power-referal-lookup {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.lvl-tm .referal-reply {
  flex: 1;
  margin-left: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lvl-tm .referal-reply > div {
  padding: 1rem;
  display: inline-block;
  border-radius: 5px;
}

.lvl-tm .referal-reply .placeholder {
  color: #aaa;
}

.lvl-tm .referal-reply .not-found {
  background-color: orange;
  color: white;
}

.lvl-tm .referal-reply .success {
  background-color: green;
  color: white;
}

main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-flow-container {
  flex: 1;
}

.lvl-tm .flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lvl-tm .flex-container .content-bulk {
  flex: 1;
  position: relative;
}

.lvl-tm .flex-container .content-bulk-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}

.lvl-tm .flex-container .summary-footer {
  padding: 2rem;
  background-color: white;
  border-top: 1px solid #ddd;
}

.lvl-tm .flex-container .summary-footer > div {
  // max-width: 1024px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem 1rem;
  justify-content: space-evenly;
  margin: 0 auto;
}

.lvl-tm .flex-container .summary-footer > div > div {
  // max-width: 1024px;
  display: flex;
  padding: 1rem;
  border: 2px solid #1d2962;
  border-radius: 5px;
  background-color: #eef8e7;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 50, 100, 0.5);
  }
  .subscriber {
    font-weight: bold;
  }
  > .info {
    flex: 1;
  }
}
